import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import SetAxisMin from 'src/javascripts/components/charts/axes/SetAxisMin';
import SetAxisMax from 'src/javascripts/components/charts/axes/SetAxisMax';
import SetAxisTicks from 'src/javascripts/components/charts/axes/SetAxisTicks';
import SetChartTotal from 'src/javascripts/components/charts/actions/SetChartTotal';
import ChangeHistoricalChartDate from 'src/javascripts/components/charts/actions/ChangeHistoricalChartDate';
import DefaultChartTooltip from 'src/javascripts/components/charts/tooltips/DefaultChartTooltip';

export default class AdminHistoricalChart extends Chart {

  getData() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url
    let url = this.el.attr('data-api-url');
    let params = {};
    let fromDate = this.el.attr('data-api-from-date');
    let status = this.el.attr('data-status');
    let statusType = this.el.attr('data-status-type');
    if (typeof fromDate !== 'undefined') {params['from_date'] = fromDate}
    if (typeof status !== 'undefined') {params['status'] = status}
    if (typeof statusType !== 'undefined') {params['status_type'] = statusType}

    // Get data
    return $.getJSON(url + "?" + $.param(params));
  }

  render(data) {

    // Set chart ID
    let chartId = this.el.attr('id')

    // Set data types
    let chartTypes = {};
    chartTypes[data[1][0]] = 'line';
    if (typeof data[2] !== 'undefined') {
      chartTypes[data[2][0]] = 'bar';
    }

    // Set axes
    let axes = {};
    axes[data[1][0]] = 'y';
    if (typeof data[2] !== 'undefined') {
      axes[data[2][0]] = 'y2';
    }

    // Set second axis max
    let yAxis2Max = 0;
    if (typeof data[2] !== 'undefined') {
      yAxis2Max = Math.max.apply(Math,data[2].slice(1)) + 2;
    }

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {left: 0, right: 0, bottom: 0, top: 0},
      data: {
        x: 'date',
        columns: data,
        axes: axes,
        types: chartTypes,
        labels: {
          format: {
            Completed: function (v, id, i, j) {
              return v
            },
          }
        }
      },
      point: {show: false},
      axis: {
        x: {
          show: true,
          type: 'timeseries'
        },
        y: {
          show: false,
          padding: {bottom: 0, top: 20}
        },
        y2: {
          show: false,
          padding: {bottom: 0, top: 20}
        }      
      },
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return DefaultChartTooltip(chart, this, d, defaultTitleFormat, defaultValueFormat, 'decimals', 2, color);
        }
      },
      grid: {
        y: {show: false},
        x: {show: false}
      }
    });

    return chart;
  }

  setUp(chart) {

    // Return if no chart available (don't attempt to set up below)
    if (typeof chart === 'undefined') {return;}

    // Set name and total at top of chart
    SetChartTotal(chart, chart.data()[0].id);

    // On click of an 'update-chart-date' button (change date of data on chart)
    $(chart.element).closest('.chart-section').find('.update-chart-date').click(function() {
      let $fromDate = $(this).attr('data-from-date');
      return ChangeHistoricalChartDate(chart, $fromDate);
    });

    // Set chart data to allow for accessing chart programmatically (used to allow tables to add/remove data)
    // Also allows for destroying chart before Turbolinks cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    let chartId = '#' + $(chart.element).attr('id');
    $(chartId).data('c3-chart', chart);

    // Remove spinner
    $(chart.element).find('.spinner-container').remove();

    return chart;
  }

};
