import ToastFlash from 'src/javascripts/components/alerts/ToastFlash';
import Collapsible from 'src/javascripts/components/utilities/Collapsible';
import TooltipInit from 'src/javascripts/components/utilities/TooltipInit';
import AdminTable from 'src/javascripts/components/tables/AdminTable';
import AdminTableFixed from 'src/javascripts/components/tables/AdminTableFixed';
import AdminTableManual from 'src/javascripts/components/tables/AdminTableManual';
import NavbarNotifications from 'src/javascripts/components/notifications/NavbarNotifications';
import ImagePickerAdmin from 'src/javascripts/components/forms/ImagePickerAdmin';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ListCarousels from 'src/javascripts/components/lists/ListCarousels';
import 'summernote/dist/summernote-bs4.min.js'

export default class Enterprise {

  constructor() {}

  render() {

    // Lists
    ListCarousels();

    // Image picker
    if ($('.admin-filepicker').length !== 0) {
      ImagePickerAdmin();

      // On show modal
      $('.show-admin-images-modal').click(function () {
        let fieldGroupId = $(this).attr('data-field-group-id');
        $('#adminImagesModal').find('.select-image').attr('data-field-group-id', fieldGroupId);
      });

      // Apply selected image on click
      $('.select-image').click(function() {
        // Update hidden field
        let url = $('.admin-image-container.active').attr('data-image-url');
        let fieldGroupId = $(this).attr('data-field-group-id');
        $('#' + fieldGroupId).find('input[type=\'hidden\']').val(url);

        // Close modal
        $('.modal').modal('hide');

        // Append image
        let src = $('.admin-image-container.active').find('img').attr('src');
        let image = "<img src=\'" + src + " \' class=\'img-fluid\'>";
        $('#' + fieldGroupId).find('.applied-image-container').empty().append(image);

      })

    }

    // Add admin table if not already present
    if ( $(".admin-table").length > 0 )  {
      if ($(".dataTables_wrapper").length === 0) {
      	$('.admin-table').each(function() {
          let tableId = $(this).attr('id');
    	    let table = new AdminTable($('#' + tableId));
    	    $.when(table.render()).then( status => table.setUp(tableId));
      	})
      }
    }

    // Add admin table if not already present
    if ( $(".admin-table-fixed").length > 0 )  {
      if ($(".dataTables_wrapper").length === 0) {
        $('.admin-table-fixed').each(function() {
          let isMinimized = ($(this).parents('.card-list-items.collapse:not(.show)').length);
          if (!isMinimized) {
            let tableId = $(this).attr('id');
            let table = new AdminTableFixed($('#' + tableId));
            $.when(table.render()).then( status => table.setUp(tableId));
          }
        })
      }
    }

    $('.card-list-items').on('shown.bs.collapse', function () {
      let table = $(this).find('.admin-table-fixed');
      if (typeof table !== 'undefined') {
        let tableId = table.attr('id');
        table = new AdminTableFixed($('#' + tableId));
        $.when(table.render()).then( status => table.setUp(tableId));
      }
    })

    // Add manual / non-server side admin tables
    if ( $(".admin-table-manual").length > 0 )  {
  	  if ($(".dataTables_wrapper").length === 0) {
  	    $('.admin-table-manual').each(function() {
  	      let tableId = $(this).attr('id');
  	      let table = new AdminTableManual($('#' + tableId));
  	      table.render();
  	    })
  	  }
  	}

    // Summernote text editor (currently only used in admin)
    if ($('.summernote').length !== 0) {
      $('.summernote').summernote({
        minHeight: 300,
        toolbar: [
          ['font', ['bold', 'italic', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link']],
          ['view', ['fullscreen', 'codeview']],
        ]}
      );

    }


  }
}