import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class CompaniesLocationsIndex {

  constructor() {}

  render() {

    // Select all locations
    $('.select-all-locations').click(function() {
      $('#locations_table tr .checkbox').prop('checked', this.checked);
    });

    // Number display
    NumberDisplay();

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })

    // Assign on select
    if ( $(".update-selected-locations").length > 0 )  {
      $('.update-selected-locations').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let companyId = $('#sdg_activities_table').attr('data-company-id');
        let sdgActivityIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let sdgActivityId = $(this).closest('tr').attr('data-record-id');
          if (typeof sdgActivityId !== 'undefined') {
            sdgActivityIds.push(sdgActivityId);
          }
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['sdg_activity_ids'] = sdgActivityIds;
        $.ajax({
          type: 'PATCH',
          url: '/companies/' + companyId + '/locations/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#sdg_activities_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'SDG activities updated', 5000);
            $('.select-all-sdg-activities').prop('checked', false);
          },
        });
      })
    }


  }

}