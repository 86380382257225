import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Number display
  NumberDisplay();

  // Auto focus on typeahead when open a dropdown
  $('.dropdown').on('shown.bs.dropdown', function () {
    let input = $(this).find('input');
    if (typeof input !== 'undefined') {
      input.focus();
    }
  })

  // Typeaheads
  filterTypeahead('.fund-type-filter', 'fund_type', '/funds/attrs/search?attr=fund_type&', 40, 'generic');
  filterTypeahead('.fund-family-filter', 'fund_family', '/funds/attrs/search?attr=fund_family&', 40, 'generic');
  filterTypeahead('.cap-filter', 'cap', '/funds/attrs/search?attr=cap&', 40, 'generic');
  filterTypeahead('.approach-filter', 'approach', '/funds/attrs/search?attr=approach&', 40, 'generic');
  filterTypeahead('.category-filter', 'category', '/funds/attrs/search?attr=category&', 40, 'generic');
  filterTypeahead('.geography-filter', 'geography', '/funds/attrs/search?attr=geography&', 40, 'generic');
  filterTypeahead('.country-filter', 'country', '/funds/attrs/search?attr=country&', 40, 'generic');
  filterTypeahead('.asset-class-filter', 'asset_class', '/funds/attrs/search?attr=asset_class&', 40, 'generic');


  // Add multiple share classes
  $('.add-share-class').click(function() {

    // Set current index number
    let ind = $('.share-class-set').length;
    
    // Set text to append
    let text = '<div class="share-class-set border-bottom mx-n3 px-3 py-3 border-top bg-gray200">';
    text += '<div class="form-group">';
    text += '<div class="d-flex w-100">';
    text += '<div class="font-weight-bold">Symbol</div>';
    text += '<div class="ml-auto small text-uppercase text-danger">required</div>';
    text += '</div>';
    text += '<div class="font-weight-bold mb-0"></div>';
    text += '<input class="form-control" type="text" name="fund[share_classes_attributes][' + ind + '][symbol]" id="fund_share_classes_attributes_' + ind + '_symbol" data-lp-ignore="true" autocomplete="off">';
    text += '</div>';
    text += '<div class="form-group">';
    text += '<div class="d-flex w-100">';
    text += '<div class="mr-2">';
    text += '<div class="font-weight-bold">Share class name</div>';
    text += '<div class="font-weight-light">For example, "Institutional" or "R6"</div>';
    text += '</div>';
    text += '<div class="ml-auto small text-uppercase">optional</div>';
    text += '</div>';
    text += '<input class="form-control" type="text" name="fund[share_classes_attributes][' + ind + '][name]" id="fund_share_classes_attributes_' + ind + '_name" data-lp-ignore="true" autocomplete="off">';
    text += '</div>';
    text += '<div class="form-group">';
    text += '<div class="d-flex w-100">';
    text += '<div class="mr-2">';
    text += '<div class="font-weight-bold">EODH code</div>';
    text += '<div class="font-weight-light">Should be the first part of the symbol, before any period (if there is a period in the symbol).</div>';
    text += '</div>';
    text += '<div class="ml-auto small text-uppercase">optional</div>';
    text += '</div>';
    text += '<input class="form-control" type="text" name="fund[share_classes_attributes][' + ind + '][eodh_code]" id="fund_share_classes_attributes_' + ind + '_eodh_code" data-lp-ignore="true" autocomplete="off">';
    text += '</div>';
    text += '<div class="form-group">';
    text += '<div class="d-flex w-100">';
    text += '<div class="mr-2">';
    text += '<div class="font-weight-bold">EODH exchange</div>';
    text += '<div class="font-weight-light">Should be the associated exchange code, such as "US", "LSE" (London), or "TSE" (Tokyo).</div>';
    text += '</div>';
    text += '<div class="ml-auto small text-uppercase">optional</div>';
    text += '</div>';
    text += '<input class="form-control" type="text" name="fund[share_classes_attributes][' + ind + '][eodh_exchange]" id="fund_share_classes_attributes_' + ind + '_eodh_exchange" data-lp-ignore="true" autocomplete="off">';
    text += '</div>';
    text += '<div class="form-group">';
    text += '<div class="d-flex w-100">';
    text += '<div class="font-weight-bold">ISIN</div>';
    text += '<div class="ml-auto small text-uppercase">optional</div>';
    text += '</div>';
    text += '<input class="form-control" type="text" name="fund[share_classes_attributes][' + ind + '][isin]" id="fund_share_classes_attributes_' + ind + '_isin" data-lp-ignore="true" autocomplete="off">';
    text += '</div>';
    text += '<div class="form-group">';
    text += '<div class="d-flex w-100">';
    text += '<div class="mr-2">';
    text += '<div class="font-weight-bold">Expense ratio</div>';
    text += '<div class="font-weight-light">Should be in decimal format (e.g., 0.01 for 1%).</div>';
    text += '</div>';
    text += '<div class="ml-auto small text-uppercase">optional</div>';
    text += '</div>';
    text += '<input class="form-control" type="text" name="fund[share_classes_attributes][' + ind + '][expense_ratio]" id="fund_share_classes_attributes_' + ind + '_expense_ratio" data-lp-ignore="true" autocomplete="off">';
    text += '</div>';
    text += '<div class="form-group">';
    text += '<a class="mr-1 remove-new-share-class clickable font-weight-bold mb-0">Click to remove this share class</a>';
    text += '</div>';
    text += '</div>';

    // Append
    $('.share-class-fields').append(text);

    // Remove new on click
    $('.remove-new-share-class').off().on('click', function() {
      $(this).closest('.share-class-set').remove();
    })

  })


}