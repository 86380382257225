import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';

export default class CompaniesIndex {

  constructor() {}

  render() {

    // Select all companies
    $('.select-all-companies').click(function() {
      $('#companies_table tr .checkbox').prop('checked', this.checked);
    });

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let analystsTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(analystsTypeahead.initializeBloodhound() )
       .then( bloodhound => analystsTypeahead.render(bloodhound) )
       .then( analystsTypeahead.setUp('.users-typeahead'));
    }

  	// Init filter typeaheads
    filterTypeahead('.sector-filter', 'sectors', '/companies/attrs/search?attr=sector&', 40, 'generic');
    filterTypeahead('.industry-filter', 'industries', '/companies/attrs/search?attr=industry&', 115, 'generic');
    filterTypeahead('.peer-group-filter', 'peer_groups', '/companies/attrs/search?attr=peer_group&', 500, 'generic');
    filterTypeahead('.region-filter', 'regions', '/companies/attrs/search?attr=region&', 18, 'generic');
    filterTypeahead('.market-cap-filter', 'market_caps', '/companies/attrs/search?attr=market_cap&', 200, 'generic');

	  // On typeahead select, create a filter
	  $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

	    // Prevent default
	    ev.preventDefault();

	    // Return if has class disabled
	    if ($(this).hasClass('disabled')) {return;}

	    // Hide tooltip
	    $('.tooltip').tooltip('hide');

	    // Set value to symbol if a fund; otherwise value
	    let val;
	    if (typeof suggestion.name !== 'undefined') {
	      val = suggestion.value;
	    } else if (typeof suggestion.value !== 'undefined') {
	      val = suggestion.value;
	    } else {
	      val = suggestion;
	    }

	    // Update attr in table
	    let attribute = $(this).attr('data-attribute');
	    $('.admin-table-fixed').attr('data-' + attribute, val);

	    // Reload
	    ReloadWithFilters('companies_table');

	  });

  	// Auto focus on typeahead when open a dropdown
		$('.dropdown').on('shown.bs.dropdown', function () {
			let input = $(this).find('input');
			if (typeof input !== 'undefined') {
				input.focus();
			}
  	})

    // Assign on select
    if ( $(".update-selected-companies").length > 0 )  {
      $('.update-selected-companies').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let companyIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let recordId = $(this).closest('tr').attr('data-record-id');
          companyIds.push(recordId);
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['company_ids'] = companyIds;
        $.ajax({
          type: 'PATCH',
          url: '/companies/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#companies_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Company records updated', 5000);
            $('.select-all-companies').prop('checked', false);
          },
        });
      })
    }

  }

}