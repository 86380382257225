import Spinner from 'src/javascripts/components/utilities/Spinner';
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';

export default class CompaniesFilesImportsNew {

  constructor() {}

  render() {

    // Upload file
    // Set environment for AWS path vars
    $('.company-filepicker').off().each(function(i, elem) {
      let fileInput     = $(elem);
      let env           = fileInput.data("env");
      var progressBar   = $("<div class='bar'></div>");
      var barContainer  = $("<div class='progress'></div>").append(progressBar);
      let backLink      = $('.company-filepicker').attr('data-back-link');
      $('#fileUploadProgress').append(barContainer);
      fileInput.fileupload({
        fileInput:        fileInput,
        url:              fileInput.data('url'),
        type:             'POST',
        autoUpload:       true,
        formData:         fileInput.data('form-data'),
        paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
        dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
        replaceFileInput: false,
        progressall: function (e, data) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.css('width', progress + '%')
        },
        start: function (e) {
          progressBar.
            css('background', '#8c81d1').
            css('display', 'block').
            css('width', '0%').
            text("Loading...");
        },
        done: function(e, data) {
          progressBar.text("Uploading done");

          // extract key from response
          let key = $(data.jqXHR.responseXML).find("Key").text();

          // Set spinner so user knows in progress
          $('.filepicker-container .filepicker-label').addClass('opaque');
          Spinner($('.filepicker-container'));

          // Check whether uploaded file is csv or xls
          if (key.slice(-4) !== '.csv') {
            let url = '/companies/files/imports/new';
            window.location.href = url + '&notice=Only .csv files allowed';

          } else {
            let url = '/companies/files/imports';
            let formData = {};
            let scope = $('#newCompanyImportModal').attr('data-scope');
            formData['scope'] = scope;
            formData['file'] = {};
            formData['file']['key'] = key;

            // Update local attribute
            return $.ajax({
              type: 'POST',
              url: url,
              dataType: "application/json",
              data: formData,
              complete(result) { 
                let params = {};
                let responseText = JSON.parse(result.responseText);
                params["file_id"] = responseText.file_id;
                params["back_path"] = backLink;
                let url = window.location.href.split('?')[0];
                return window.location.href = url + '?' + $.param(params);
              },
            });

          }

        },
        fail: function(e, data) {
          progressBar.
            css("background", "red").
            text("Failed");
        }
      });
    });

    // Set poll function to poll server for status of import
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let fileId = isGenerating.attr('data-file-id');
        let url = '/companies/files/imports/check_import_status?file_id=' + fileId;

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url + '.js?',
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Check for import poll
    importPoll();

  }

}