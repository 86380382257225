export default function() {

  // Remove 'overflow visible' from carousel on click of next/prev arrows
  $('.carousel').on('slid.bs.carousel', function() {

    // Set vars
    let $section = $(this).closest('.card-list');
    let $iLength = $(this).find(".carousel-item").length;
    let $index = $(this).find(".carousel-item.active").index();
    let $nextIndex = $index + 1;

    // Hide/unhide arrows depending on position
    if ($nextIndex === $iLength) {
      $(this).find('.suggestions-arrow.right').addClass('d-none');
      $(this).find('.suggestions-arrow.left').removeClass('d-none');
    } else if ($nextIndex === 1) {
      $(this).find('.suggestions-arrow.right').removeClass('d-none');
      $(this).find('.suggestions-arrow.left').addClass('d-none');
    } else {
      $(this).find('.suggestions-arrow.right').removeClass('d-none');
      $(this).find('.suggestions-arrow.left').removeClass('d-none');
    }

    // Reset to start if on last item
    if ($nextIndex === $iLength + 1) {$nextIndex = 1;}
    $section.find('.indicators .circle.active').removeClass('active');
    $section.find('.indicators .circle:nth-child(' + $nextIndex + ')').addClass('active');

  });

  $('.card-list-items.collapse').on('show.bs.collapse', function () {
    // Set section
    let $section = $(this).closest('.card-list');
    // Toggle collapse item on 
    $section.find('.toggleable').toggleClass('d-none');
    // Toggle section background
    $section.addClass('active');
  });


  $('.card-list-items.collapse').on('hide.bs.collapse', function () {
    // Set section
    let $section = $(this).closest('.card-list');
    // Toggle collapse item on 
    $section.find('.toggleable').toggleClass('d-none')
    // Toggle section background
    $section.removeClass('active');
  });

}