import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default function() {

  const getNotifications = function() {

    // Set url to get data
    let dataUrl = '/notifications/navbar';

    // Set empty hash to build params
    let params = {};

    // Set record count to load
    params['offset'] = $('.navbar-notifications').attr('data-api-offset');
    params['length'] = $('.navbar-notifications').attr('data-api-length');

    // Execute ajax request to get notifications for current user
    // Must specify '.js' otherwise processes as JSON
    return $.ajax({
      type: "GET",
      dataType: "script",
      url: dataUrl + '.js?' + $.param(params)
    });

  }


  // Custom notifications dropdown, bootstrap has issues with clicking inside a dropdown
  // https://stackoverflow.com/questions/25089297/avoid-dropdown-menu-close-on-click-inside/25196101#25196101
  $('.top-nav-dropdown a.nav-link').click(function(e) {

    // Prevent default
    e.preventDefault();

    // Close other menus
    $('.top-nav-dropdown').not($(this).closest('li')).removeClass('show');

    // Open menu
    $(this).parent().toggleClass('show');

    if ($(this).closest('li').attr('id') === 'navbarNotificationsItem') {
    
      // Add spinner to dropdown menu
      Spinner($('#navbarNotificationsMenu'));

      // Get data if shown
      if ( $(this).parent().hasClass('show') ) {
        getNotifications();
      }

    }

  });

  $('body').on('click', function (e) {
    
    // Set dropdown item
    let d = $('.top-nav-dropdown');

    // Remove open class if clicked outside the dropdown
    if ( !d.is(e.target) && d.has(e.target).length === 0 && $('.show').has(e.target).length === 0) {
      d.removeClass('show');
    }

  });

}