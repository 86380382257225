import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

export default class CompaniesMetricScoresNew {

  constructor() {}

  render() {

  	filterTypeahead('.metrics-typeahead', 'metrics', '/metrics/search?', 550, 'cause');

	  // On typeahead select, fill associated field
	  $('.metrics-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
	    ev.preventDefault();
      $(this).closest('.input-group').find('input[type=\'hidden\']').val(suggestion.value);
      $(this).typeahead('val', suggestion.name_short);
      $(this).blur();
	  });

    $('.remove-conversion').click(function() {
      $('[data-toggle=tooltip]').tooltip('hide');
      let row = $(this).closest('.form-row');
      row.addClass('d-none');
      row.find('.destroy-conversion').val(true);
    });

    // Number display
    let decimals = $('.number-display').attr('data-scale');
    if (typeof decimals === 'undefined') {decimals = 2;}
    $('.number-display').number( true, decimals );

    // Update hidden field on data entry
    $('.number-display').on("change paste keyup", function() {
      let val = $(this).val();
      let hiddenInput = $(this).closest('.form-group').find('input[type=\'hidden\']');
      hiddenInput.val(val);
    });

    // Send conversion request on submit  
    $('.number-display:not(.final-data)').on("blur", function() {
      let formRow = $(this).closest('.form-row');
      let companyId = $('#company_metric_score_company_id').val()
      let metricScoreId = $('#company_metric_score_id').val();
      let conversionId = formRow.attr('data-conversion-id');
      let originalData = formRow.find('input.original-data').val();
      let originalUom = formRow.find('select.original-uom').find(":selected").text();
      let params = {};
      if (typeof originalUom !== 'undefined') {params['original_uom'] = originalUom;}
      if (typeof originalData !== 'undefined') {params['original_data'] = originalData;}
      $.ajax({
        type: 'POST',
        dataType: 'application/json',
        url: "/companies/" + companyId + "/metric_scores/" + metricScoreId + "/" + conversionId + "/save_conversion?" + $.param(params),
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
          let convertedData = responseText.conversion.converted_data;
          formRow.find('input.converted-data').val(convertedData);
          $('#metric_score_score_base').val(responseText.metric_score.score_base);
        },
      });
    });

    // Remove conversion
    $('.remove-conversion').off().click(function() {
      $('[data-toggle=tooltip]').tooltip('hide');
      let formRow = $(this).closest('.form-row');
      let companyId = $('#company_metric_score_company_id').val()
      let metricScoreId = $('#company_metric_score_id').val();
      let conversionId = formRow.attr('data-conversion-id');
      $.ajax({
        type: 'DELETE',
        dataType: 'application/json',
        url: "/companies/" + companyId + "/metric_scores/" + metricScoreId + "/" + conversionId + "/remove_conversion",
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
          formRow.remove();
          $('#metric_score_score_base').val(responseText.metric_score.score_base);
        },
      });

    });

    // On change UOM
    $('.original-uom').off().on('change', function() {
      let formRow = $(this).closest('.form-row');
      let companyId = $('#company_metric_score_company_id').val()
      let metricScoreId = $('#company_metric_score_id').val();
      let conversionId = formRow.attr('data-conversion-id');
      let originalData = formRow.find('input.original-data').val();
      let originalUom = formRow.find('select.original-uom').find(":selected").text();
      let params = {};
      if (typeof originalUom !== 'undefined') {params['original_uom'] = originalUom;}
      if (typeof originalData !== 'undefined') {params['original_data'] = originalData;}
      $.ajax({
        type: 'POST',
        dataType: 'application/json',
        url: "/companies/" + companyId + "/metric_scores/" + metricScoreId + "/" + conversionId + "/save_conversion?" + $.param(params),
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
          let convertedData = responseText.conversion.converted_data;
          formRow.find('input.converted-data').val(convertedData);
          $('#metric_score_score_base').val(responseText.metric_score.score_base);
        },
      });
    });

    // On review assignment
    $('.review-assignment-field').off().on('change', function() {
      let val = $(this).val();
      if (val === "") {
        $('#review_assignment_destroy').val('true')
      } else {
        $('#review_assignment_destroy').val('false')
      }
    })

  }

}