import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class CompaniesControversiesIndex {

  constructor() {}

  render() {

    // Select all controversies
    $('.select-all-controversies').click(function() {
      $('#controversies_table tr .checkbox').prop('checked', this.checked);
    });

    // Number display
    NumberDisplay();

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })

    // Assign on select
    if ( $(".update-selected-controversies").length > 0 )  {
      $('.update-selected-controversies').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let companyId = $('#controversies_table').attr('data-company-id');
        let controversyIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let controversyId = $(this).closest('tr').attr('data-record-id');
          if (typeof controversyId !== 'undefined') {
            controversyIds.push(controversyId);
          }
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['controversy_ids'] = controversyIds;
        $.ajax({
          type: 'PATCH',
          url: '/companies/' + companyId + '/controversies/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#controversies_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Controversies updated', 5000);
            $('.select-all-controversies').prop('checked', false);
          },
        });
      })
    }

    // Lock/unlock all
    if ( $(".lock-all-controversies").length > 0 )  {
      $('.lock-all-controversies').click(function() {
        let lock = $(this).attr('data-lock');
        let companyId = $('#controversies_table').attr('data-company-id');
        let params = {};
        params['lock'] = lock;
        $.ajax({
          type: 'PATCH',
          url: '/companies/' + companyId + '/controversies/lock_all?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#controversies_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Metric scores updated', 5000);
            $('.select-all-controversies').prop('checked', false);
          },
        });
      })
    }


  }

}