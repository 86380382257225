import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

// Pick an image and update an associated attribute in DB
export default function() {

  // Set environment for AWS path vars
  $('.filepicker').each(function(i, elem) {
    let fileInput     = $(elem);
    let symbol        = fileInput.data("symbol");
    let recordId      = fileInput.data("record-id");
    let backPath      = fileInput.data("back-path");
    var progressBar   = $("<div class='bar'></div>");
    var barContainer  = $("<div class='progress'></div>").append(progressBar);
    $('#imageUploadProgress').addClass('mb-2').append(barContainer);
    fileInput.fileupload({
      fileInput:        fileInput,
      url:              fileInput.data('url'),
      type:             'POST',
      autoUpload:       true,
      formData:         fileInput.data('form-data'),
      paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%')
      },
      start: function (e) {
        progressBar.
          css('background', '#8c81d1').
          css('display', 'block').
          css('width', '0%').
          text("Loading...");
      },
      done: function(e, data) {
        progressBar.text("Uploading done");

        $('.modal-body').css('opacity', 0.3);
        Spinner($('.modal-content'));

        // extract key from response
        let key = $(data.jqXHR.responseXML).find("Key").text();
        let file_url = key;

        // Check for presence of PDF
        let pdf = file_url.includes('pdf');
        let csv = file_url.includes('csv');
        let xlsx = file_url.includes('xlsx');
        if ((pdf === false) && (xlsx === false) && (csv === false)) {
          $('.modal-content').find('.spinner-container').remove();
          ToastCustom('Only PDFs, CSV, and XLSX allowed', 'Currently only PDFs, CSV, and XLSX files are allowed to be uploaded', 7000);
          return 
        }

        // Create image in local DB
        let updateUrl;
        let formData;
        updateUrl = '/companies/' + recordId + '/files';
        formData = {'company_file': {'file_url': file_url}};

        // Update local attribute
        return $.ajax({
          type: 'POST',
          url: updateUrl,
          dataType: "application/json",
          data: formData,
          complete(result) { 
            $('.modal-content').find('.spinner-container').remove();
            let url = window.location.href.split('?')[0];
            url += "?tab=backup_docs";
            if (typeof recordId !== 'undefined') {url += "&record_id=" + recordId}
            return Turbolinks.visit(url);
          },
        });

      },
      fail: function(e, data) {
        progressBar.
          css("background", "red").
          text("Failed");
      }
    });
  });

}