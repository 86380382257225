import FundsForm from 'src/javascripts/components/forms/FundsForm';

export default class FundsNew {

  constructor() {}

  render() {

    // Call associated form js
    FundsForm();

  }

}