import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

export default class CompaniesMappingsNew {

  constructor() {}

  render() {

    // Init filter typeaheads
    filterTypeahead('#companies_typeahead', 'companies', '/companies/search?', 172, 'logo');

    // On typeahead select, create a filter
    $('#companies_typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Set value to symbol if a fund; otherwise value
      let val = suggestion.value;

      // Update attr
      $(this).closest('.form-group').find('input[type=\'hidden\']').val(val);

      // Update and blur
      $(this).typeahead('val', suggestion.name);
      $(this).blur();

    });

  }

}