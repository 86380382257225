export default function() {

	if ($('.collapse-item').length !== 0) {

		// Collapse item on click
		$('.collapse-item').off('click').on('click', function() {
			
			// Toggle minimized class for closest collapsible item
			let $closed = $(this).closest('.collapsible-item');
			$closed.toggleClass('minimized');

			// Toggle chevron for THIS item
			let $toggleable = $(this).find('.toggleable');
			if ( ($toggleable.hasClass('fa-chevron-down')) || ($toggleable.hasClass('fa-chevron-up')) ) {
				$toggleable.toggleClass('fa-chevron-down').toggleClass('fa-chevron-up');
			} else {
				$toggleable.toggleClass('fa-plus-circle').toggleClass('fa-minus-circle');
			}

			// Collapse all sibling collapsible items, unless class 'allow-multiple-open'
			if ( !$closed.hasClass('allow-multiple-open') ) {
				let $open = $closed.siblings('.collapsible-item').not('.minimized');
				$open.addClass('minimized');
				let $toggleable = $open.find('.toggleable');
				if ( ($toggleable.hasClass('fa-chevron-down')) || ($toggleable.hasClass('fa-chevron-up')) ) {
					$toggleable.removeClass('fa-chevron-up').addClass('fa-chevron-down');
				} else {
					$toggleable.removeClass('fa-minus-circle').addClass('fa-plus-circle');
				}
				// Scroll to top of previously opened divs; required for recs modal
				$open.scrollTop(0)
			}

		})

	}

}