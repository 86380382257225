// Manual / non-server side Admin tables
import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';

export default class AdminTableManual extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Set order from table attr or default
    let colLen = $('#' + tableId + ' th').length;
    let orderCol = $('#' + tableId).attr('data-order-col') || colLen - 1;

    // Render table
    const table = $('#' + tableId).DataTable({
      pageLength: 10,
      scrollx: true,
      dom: 'frltp',
      ordering: true,
      order: [[orderCol, 'desc']],
      createdRow(row, data, dataIndex) {

        // Add hoverlink buttons to rows
        let link1 = $(row).attr('data-view-url');
        let name1 = 'View';
        let link2 = $(row).attr('data-edit-url');
        let name2 = 'Edit';
        let linkObjects = [];
        if (typeof link1 !== 'undefined') { linkObjects.push({link: link1, name: name1}); }
        if (typeof link2 !== 'undefined') { linkObjects.push({link: link2, name: name2}); }
        AddRowHoverButtons(row, linkObjects);

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();

        // On click of row
        $('#' + tableId + ' tbody td').click(function() {
          let url = $(this).closest('tr').attr('data-view-url');
          if (typeof url !== 'undefined') {
            return Turbolinks.visit(url);
          }
        });

      }

    });

    return 'rendered';

  }
};