import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';

export default class CompaniesEdit {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let analystsTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(analystsTypeahead.initializeBloodhound() )
       .then( bloodhound => analystsTypeahead.render(bloodhound) )
       .then( analystsTypeahead.setUp('.users-typeahead'));
    }

    // Select all metric scores
    $('.select-all-metric-scores').click(function() {
      $('#metric_scores_table tr .checkbox').prop('checked', this.checked);
    });

    // Init filter typeaheads
    filterTypeahead('.category-filter', 'categories', '/metrics/attrs/search?attr=category&', 83, 'generic');
    filterTypeahead('.source-name-filter', 'source_name', '/metrics/attrs/search?attr=impact_sources.name&', 115, 'generic');
    
    // Set url for metric score dates
    let metricScoreCompanyId = $('.date-filter').attr('data-company-id');
    let metricScoreUrl = '/metric_scores/attrs/search?company_id=' + metricScoreCompanyId + '&attr=date&'
    filterTypeahead('.date-filter', 'dates', metricScoreUrl, 155, 'generic');

    // On typeahead select, create a filter
    $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value to symbol if a fund; otherwise value
      let val;
      if (typeof suggestion.name !== 'undefined') {
        val = suggestion.value;
      } else if (typeof suggestion.value !== 'undefined') {
        val = suggestion.value;
      } else {
        val = suggestion;
      }

      // Update attr in table
      let attribute = $(this).attr('data-attribute');
      $('#metric_scores_table').attr('data-' + attribute, val);

      // Reload
      ReloadWithFilters('metric_scores_table');

    });

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })

    // Assign on select
    if ( $(".update-selected-metric-scores").length > 0 )  {
      $('.update-selected-metric-scores').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let companyId = $('#metric_scores_table').attr('data-company-id');
        let metricScoreIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let metricScoreId = $(this).closest('tr').attr('data-record-id');
          metricScoreIds.push(metricScoreId);
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['metric_score_ids'] = metricScoreIds;
        $.ajax({
          type: 'PATCH',
          url: '/companies/' + companyId + '/metric_scores/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#metric_scores_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Metric scores updated', 5000);
            $('.select-all-metric-scores').prop('checked', false);
          },
        });
      })
    }

    // Lock/unlock all
    if ( $(".lock-all-metric-scores").length > 0 )  {
      $('.lock-all-metric-scores').click(function() {
        let lock = $(this).attr('data-lock');
        let companyId = $('#metric_scores_table').attr('data-company-id');
        let params = {};
        params['lock'] = lock;
        $.ajax({
          type: 'PATCH',
          url: '/companies/' + companyId + '/metric_scores/lock_all?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#metric_scores_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Metric scores updated', 5000);
            $('.select-all-metric-scores').prop('checked', false);
          },
        });
      })
    }

    // Assign on select
    if ( $(".delete-selected-metric-scores").length > 0 )  {
      $('.delete-selected-metric-scores').click(function() {
        let companyId = $('#metric_scores_table').attr('data-company-id');
        let metricScoreIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let metricScoreId = $(this).closest('tr').attr('data-record-id');
          metricScoreIds.push(metricScoreId);
        })
        let params = {};
        params['metric_score_ids'] = metricScoreIds;
        $.ajax({
          type: 'DELETE',
          url: '/companies/' + companyId + '/metric_scores/bulk_delete?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#metric_scores_table').DataTable().ajax.reload();
            ToastCustom('Removed', 'Metric scores removed', 5000);
            $('.select-all-metric-scores').prop('checked', false);
          },
        });
      })
    }


  }

}