import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import Swal from 'sweetalert2';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class MetricsIndex {

  constructor() {}

  render() {

    // Init filter typeaheads
    filterTypeahead('.sources-filter', 'sources', '/sources/search?', 172, 'generic');

    // On typeahead select, create a filter
    $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Update attr in table
      let attribute = $(this).attr('data-attribute');
      let arr = [];
      let currentAttr = $('#metrics_table').attr('data-' + attribute);
      if (typeof currentAttr !== "undefined") {arr.push(currentAttr);}
      arr.push(suggestion.value);
      $('#metrics_table').attr('data-' + attribute, suggestion.value);

      console.log(attribute);

      // Reload
      ReloadWithFilters('metrics_table');

    });

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })


  }

}