import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
const Bloodhound = require('src/javascripts/vendor/bloodhound');
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';

export default class UsersTypeahead {

  constructor(el) {this.el = el;}

  initializeBloodhound() {

    let apiUrl = $(this.el).attr('data-api-url');
    const bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: apiUrl + '?query=%QUERY',
        wildcard: "%QUERY"
      }});

    bloodhound.initialize();
    return bloodhound;

  }

  render(bloodhound, autocomplete = 'off') {

    let emptyTemplate = '<div class="empty-message">Unable to find that</div>';

    let userTemplate = (name) => 
      '<div>' + name + '</div>';

    $(this.el).typeahead({
      hint: true,
      highlight: true,
      minLength: 0
    }, {
      name: 'typeahead',
      display: 'name',
      limit: 104,
      source: bloodhound.ttAdapter(),
      templates: {
        empty: emptyTemplate,
        suggestion(el) {
          return userTemplate(el.name);
        }
      }
    });
  }

  setUp() {

    $('.users-typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 
      let attribute = $(this).attr("data-attribute");
      $('.admin-table-fixed').attr('data-' + attribute, suggestion.value);
      ReloadWithFilters();
    })
  }

}
