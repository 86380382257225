import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';

export default class CompaniesSdgActivitiesNew {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Update hidden field on type of text field
    $(".value-text").on("change paste keyup", function() {

      // skip for arrow keys
      if(event.which >= 37 && event.which <= 40) return;

      // Update related numeric input (remove comma, return to value)
      let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
      let numericVal = parseFloat( $(this).val() );
      if (Number.isNaN(numericVal)) {
        numericVal = 0;
        numericInput.val(numericVal);
      } else if (numericVal >= 1000000000000) {
        numericVal = 1;
        numericInput.val(numericVal);
        $(this).val(('100'));
        ToastCustom('Too large', 'Max value is 100', 3000);
      } else {
        numericInput.val(numericVal / 100);
      }
    });


  }

}