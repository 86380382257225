import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';

export default class CompaniesScreensIndex {

  constructor() {}

  render() {

    // Select all screens
    $('.select-all-screens').click(function() {
      $('#screens_table tr .checkbox').prop('checked', this.checked);
    });

    // Number display
    NumberDisplay();

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let analystsTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(analystsTypeahead.initializeBloodhound() )
       .then( bloodhound => analystsTypeahead.render(bloodhound) )
       .then( analystsTypeahead.setUp('.users-typeahead'));
    }

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })

    // Assign on select
    if ( $(".update-selected-screens").length > 0 )  {
      $('.update-selected-screens').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let companyId = $('#screens_table').attr('data-company-id');
        let impactScreenIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let impactScreenId = $(this).closest('tr').attr('data-impact-screen-id');
          impactScreenIds.push(impactScreenId);
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['impact_screen_ids'] = impactScreenIds;
        $.ajax({
          type: 'PATCH',
          url: '/companies/' + companyId + '/screens/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#screens_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Screens updated', 5000);
            $('.select-all-screens').prop('checked', false);
          },
        });
      })
    }

    // Lock/unlock all
    if ( $(".lock-all-screens").length > 0 )  {
      $('.lock-all-screens').click(function() {
        let lock = $(this).attr('data-lock');
        let companyId = $('#screens_table').attr('data-company-id');
        let params = {};
        params['lock'] = lock;
        $.ajax({
          type: 'PATCH',
          url: '/companies/' + companyId + '/screens/lock_all?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#screens_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Screens updated', 5000);
            $('.select-all-screens').prop('checked', false);
          },
        });
      })
    }


  }

}