import Swal from 'sweetalert2';

// ==============================================================
// No data available
// ==============================================================

export default function() {

  Swal.fire({
    title: 'No data available',
    text: "Sorry, we couldn't find any data for that selection. Please choose another selection or contact us for help",
    animation: false,
    focusConfirm: false,
    confirmButtonText: 'OK',
    customClass: {
      confirmButton: 'btn btn-primary-light',
      popup: 'animated fadeIn faster'
    }
  });
}