import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class FundsIndex {

  constructor() {}

  render() {

    // Select all funds
    $('.select-all-weights').click(function() {
      $('#fund_weights_table tr .checkbox').prop('checked', this.checked);
    });

  	// Auto focus on typeahead when open a dropdown
		$('.dropdown').on('shown.bs.dropdown', function () {
			let input = $(this).find('input');
			if (typeof input !== 'undefined') {
				input.focus();
			}
  	})


    // Set poll function to poll server for status of import
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let fundId = isGenerating.attr('data-fund-id');
        let fileId = isGenerating.attr('data-file-id');
        let url = '/funds/' + fundId + '/files/' + fileId + '/check_mapping_status.js?';

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Check for import poll
    importPoll();

  }

}
