// Add data attributes to created rows on a table
// Add attributes based on passed data, if data available

export default function(row, data) {

  // Core record attributes required for multiple functions
  if (typeof data.name !== 'undefined') { $(row).attr("data-record-name", data.name); }
  if (typeof data.record_type !== 'undefined') { $(row).attr("data-record-type", data.record_type); }
  if (typeof data.record_id !== 'undefined') { $(row).attr("data-record-id", data.record_id); }
  if (typeof data.metric_id !== 'undefined') { $(row).attr("data-metric-id", data.metric_id); }
  if (typeof data.company_id !== 'undefined') { $(row).attr("data-company-id", data.company_id); }
  if (typeof data.impact_screen_id !== 'undefined') { $(row).attr("data-impact-screen-id", data.impact_screen_id); }
  if (typeof data.token !== 'undefined') { $(row).attr("data-record-token", data.token); }
  if (typeof data.profile_type !== 'undefined') { $(row).attr("data-profile-type", data.profile_type); }
  if (typeof data.profile_id !== 'undefined') { $(row).attr("data-profile-id", data.profile_id); }

  // Add view url
  if (typeof data.view_url !== 'undefined') { $(row).attr("data-view-url", data.view_url); }

  // Add class
  if (typeof data.record_id !== 'undefined') { $(row).addClass(data.record_id.toString()); }
  if ((typeof data.locked !== 'undefined') && (data.locked === true)) { $(row).addClass("locked"); }

  // Impact formula attribute used in net impact navigation
  if (typeof data.formula_id !== 'undefined') { $(row).attr("data-formula-id", data.formula_id); }
  if (typeof data.slug !== 'undefined') { $(row).attr("data-slug", data.slug); }

};