import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class AdminTableFixed extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner($(this.el).closest('.card'));

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    const dataUrl = BuildApiUrl(tableId);

    // Set additional data vars (used below in table creation)
    const cols = GetCols(this.el);

    // Set order col and dir
    let orderCol = $('#' + tableId).attr('data-order-col');
    if (typeof orderCol === 'undefined') {orderCol = 0};
    let orderDir = $('#' + tableId).attr('data-order-dir');
    if (typeof orderDir === 'undefined') {orderDir = 'asc'};

    // Left fixed
    let leftFixed = $('#' + tableId).attr('data-left-fixed');
    if (typeof leftFixed === 'undefined') {leftFixed = 1};

    // Length menu
    let lengthMenu;
    let lengthAll = $('#' + tableId).attr('data-length-all');
    if (typeof lengthAll === 'undefined') {
      lengthMenu = [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]];
    } else if (lengthAll === 'false') {
      lengthMenu = [[10, 25, 50, 100], [10, 25, 50, 100]];
    } else {
      lengthMenu = [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]];      
    }

    // Set whether to order or not
    let ordering = $('#' + tableId).attr('data-ordering');
    if (typeof ordering === 'undefined') {ordering = false;}

    // Auto width
    let autoWidth = $('#' + tableId).attr('data-auto-width');
    if (typeof autoWidth === 'undefined') {autoWidth = true;}

    // Set scroll-y
    let scrollY = $('#' + tableId).attr('data-scroll-y');
    if (typeof scrollY === 'undefined') {scrollY = 535;}

    // Length
    let length = 50;
    let lengthInd = $('#' + tableId).attr('data-length');
    if (typeof lengthInd !== 'undefined') {length = lengthInd;}

    // Render table
    // Most table options handled through data attrs on table element
    // https://datatables.net/manual/options
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    // orderCellsTop: required with adding searchable column in header row; without this the clickable ordering
    // gets lost behind search input
    const table = $('#' + tableId).DataTable({
      orderCellsTop: true,
      retrieve: true,
      serverSide: true,
      colReorder: true,
      scrollY: scrollY,
      scrollX: true,
      autoWidth: autoWidth,
      scrollCollapse: true,
      fixedColumns: {leftColumns: leftFixed, rightColumns: 1},
      paging: true,
      pageLength: length,
      lengthChange: true,
      lengthMenu: lengthMenu,
      info: true,
      ordering: ordering,
      order: [[orderCol, orderDir]],
      searching: true,
      ajax: dataUrl,
      dom: 'tlp',
      columns: cols,
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);


      },
      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).closest('.card-body').css('opacity', 1);

        // Number results
        let numberResults = settings.json.recordsFiltered;
        numberResults = Math.abs(numberResults).toLocaleString("en", {minimumFracctionDigits: 0, maximumFractionDigits: 0});
        $('#numberResults').empty().append("(" + numberResults + ")");

        // Percent production ready
        let percentProductionReady = settings.json.percentProductionReady;
        if (typeof percentProductionReady !== "undefined") {
          $('#percentProductionReady').empty().append(percentProductionReady);
        }

        // Re-init tooltips
        $('[data-toggle=tooltip]').tooltip();

        // Enable/disable buttons
        let maxExport = $('#' + tableId).attr('data-max-export');
        if (typeof maxExport !== 'undefined') {
          let disabledExport = $('.disabled-export');
          let enabledExport = $('.enabled-export');
          if (settings.json.recordsFiltered > parseFloat(maxExport)) {
            disabledExport.removeClass('d-none');
            enabledExport.addClass('d-none');
          } else {
            disabledExport.addClass('d-none');
            enabledExport.removeClass('d-none');            
          }
        }

        // If mark complete is present (for reviews)
        $('.update-metric-score-status').click(function() {
          $('#' + tableId).css('opacity', 0.25);
          let row = $(this).closest('tr');
          let companyId = row.attr('data-company-id');
          let metricScoreId = $(this).attr('data-metric-score-id');
          let status = $(this).attr('data-status');
          let params = {};
          params['company_metric_score'] = {};
          params['company_metric_score']['status'] = status;
          params['company_metric_score']['company_id'] = companyId;
          params['company_metric_score']['metric_score_id'] = metricScoreId;
          let url = "/companies/" + companyId + "/metric_scores/" + metricScoreId + ".json?" + $.param(params);
          $.ajax({
            type: 'PATCH',
            dataType: 'application/json',
            url: url,
            complete(result) {
              let responseText = JSON.parse(result.responseText);
              let metricScoreId = responseText.metric_score_id;
              let scoreStatus = responseText.score_status;
              let row = $('#' + tableId).find("tr[data-record-id=" + metricScoreId + "]");
              let ratingClass;
              let ratingText;
              if (scoreStatus === "production_ready") {
                ratingClass = "text-a-rating";
                ratingText = "Production ready";
              } else if (scoreStatus === "final_review_completed") {
                ratingClass = "text-b-rating";
                ratingText = "Final review completed";
              } else if (scoreStatus === "analyst_completed") {
                ratingClass = "text-c-rating";
                ratingText = "Analyst completed";
              } else if (scoreStatus === "returned_for_sm_review") {
                ratingClass = "text-d-rating";
                ratingText = "Returned for SM review";
              } else if (scoreStatus === "needs_ethos_review") {
                ratingClass = "text-f-rating";
                ratingText = "Needs Ethos review";
              } else {
                ratingClass = "text-f-rating";
                ratingText = "In queue";
              }
              row.find('.review-status').replaceWith("<span class=\'font-weight-bold review-status " + ratingClass + "\'>" + ratingText + "</span>");
              $('#' + tableId).css('opacity', 1);
              ToastCustom('Updated', 'Status updated', 5000);
            },
          });
        })

        // Lock / unlock
        if ( $(".lock-toggle").length > 0 )  {
          $('.lock-toggle').click(function() {
            let recordType = $(this).attr('data-record-type');
            let recordId = $(this).attr('data-record-id');
            let lock = $(this).attr('data-lock');
            let url = "/" + recordType + "/lock?";
            let params = {};
            params['lock'] = lock;
            params['record_id'] = recordId;
            $.ajax({
              type: 'PATCH',
              url: url + $.param(params),
              dataType: 'application/json',
              complete(result) { 
                let responseText = JSON.parse(result.responseText);
                $('#' + tableId).DataTable().ajax.reload();
              },
            });
          })
        }

        // If mark complete is present (for reviews)
        $('.update-screen-status').click(function() {
          $('#' + tableId).css('opacity', 0.25);
          let row = $(this).closest('tr');
          let companyId = row.attr('data-company-id');
          let screenId = $(this).attr('data-company-screen-id');
          let status = $(this).attr('data-status');
          let params = {};
          params['company_screen'] = {};
          params['company_screen']['status'] = status;
          params['company_screen']['company_id'] = companyId;
          let url = "/companies/" + companyId + "/screens/" + screenId + ".json?" + $.param(params);
          $.ajax({
            type: 'PATCH',
            dataType: 'application/json',
            url: url,
            complete(result) {
              let responseText = JSON.parse(result.responseText);
              let screenId = responseText.screen_id;
              let scoreStatus = responseText.score_status;
              let row = $('#' + tableId).find("tr[data-record-id=" + screenId + "]");
              let ratingClass;
              let ratingText;
              if (scoreStatus === "production_ready") {
                ratingClass = "text-a-rating";
                ratingText = "Production ready";
              } else if (scoreStatus === "final_review_completed") {
                ratingClass = "text-b-rating";
                ratingText = "Final review completed";
              } else if (scoreStatus === "analyst_completed") {
                ratingClass = "text-c-rating";
                ratingText = "Analyst completed";
              } else if (scoreStatus === "returned_for_sm_review") {
                ratingClass = "text-d-rating";
                ratingText = "Returned for SM review";
              } else if (scoreStatus === "needs_ethos_review") {
                ratingClass = "text-f-rating";
                ratingText = "Needs Ethos review";
              } else {
                ratingClass = "text-f-rating";
                ratingText = "In queue";
              }
              row.find('.review-status').replaceWith("<span class=\'font-weight-bold review-status " + ratingClass + "\'>" + ratingText + "</span>");
              $('#' + tableId).css('opacity', 1);
              ToastCustom('Updated', 'Status updated', 5000);
            },

          });

        })


      }

    });

    return 'rendered';

  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // On click of row
    $('#' + tableId).on('click', 'tbody > tr > td:not(:first-child):not(:last-child)', function() { 
      let url = $(this).closest('tr').attr('data-view-url');
      if (typeof url !== 'undefined') {
        return Turbolinks.visit(url);
      }
    });

    return 'setup';

  }


};
