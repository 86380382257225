import CompaniesForm from 'src/javascripts/components/forms/CompaniesForm';

export default class CompaniesEdit {

  constructor() {}

  render() {

    // Call associated form js
    CompaniesForm();

  }

}