import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';

export default class CompaniesMetricScoresEdit {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

  	filterTypeahead('.metrics-typeahead', 'metrics', '/metrics/search?', 550, 'cause');

	  // On typeahead select, create a filter
	  $('.metrics-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
	    ev.preventDefault();
      let val = suggestion.value;
      $('#company_metric_score_metric_id').val(val);
      $('.admin-form').submit();
	  });

    $('.remove-conversion').click(function() {
      $('[data-toggle=tooltip]').tooltip('hide');
      let row = $(this).closest('.form-row');
      row.addClass('d-none');
      row.find('.destroy-conversion').val(true);
    });

  }

}