import FundWeightsForm from 'src/javascripts/components/forms/FundWeightsForm';

export default class FundWeightsEdit {

  constructor() {}

  render() {

    // Call associated form js
    FundWeightsForm();

  }

}