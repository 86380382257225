// Scroll to anchor
export default function(tag) {

  let aTag = $('#' + tag);
  let isModal = aTag.closest('.modal-body');
  if (isModal.length > 0) {
  	let bid = aTag.closest('.modal-body').attr('id');
  	return $('#' + bid).animate({ scrollTop: aTag.position().top}, 'slow');
  } else {
  	return $('html,body').animate({ scrollTop: aTag.offset().top - 80}, 'slow');
  }

}