import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

export default class CompaniesControversiesNew {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Companies typeahead
    filterTypeahead('#companies_search', 'companies', '/companies/search?', 172, 'logo');

    // On typeahead select, update hidden field
    $('#companies_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value
      $('#hidden_company_id').val(suggestion.value);

      // Blur
      $(this).blur();

    });

    // Update hidden field on type of text field
    $(".value-text").on("change paste keyup", function() {

      // skip for arrow keys
      if(event.which >= 37 && event.which <= 40) return;

      // Update related numeric input (remove comma, return to value)
      let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
      let numericVal = parseFloat( $(this).val() );
      if (Number.isNaN(numericVal)) {
        numericVal = 0;
        numericInput.val(numericVal);
      } else if (numericVal >= 1000000000000) {
        numericVal = 100000;
        numericInput.val(numericVal);
        $(this).val(('100000'));
        ToastCustom('Too large', 'Max value is $999,999,999,999', 3000);
      } else {
        numericInput.val(numericVal);
      }
    });

    // Select cont category
    $('.controversy-tag-check').change(function() {
      let checked = this.checked;
      if (checked) {
        $(this).closest('.form-group').find('.hidden-destroy').val('false');
      } else {
        $(this).closest('.form-group').find('.hidden-destroy').val('true');
      }
    });

    // Remove controversy source
    $('.controversy-source-check').change(function() {
      let checked = this.checked;
      if (checked) {
        $(this).closest('.controversy-source-row').find('.hidden-destroy').val('true');
      } else {
        $(this).closest('.controversy-source-row').find('.hidden-destroy').val('false');
      }
    });

    // Add/remove source
    $(".cont-source-field").on("change paste keyup", function() {
      if(event.which >= 37 && event.which <= 40) return;
      if ($(this).val().length > 0) {
        $(this).closest('.controversy-source-row').find('.hidden-destroy').val('false');
      } else {
        $(this).closest('.controversy-source-row').find('.hidden-destroy').val('true');
      }
    });

  }

}