import AdminHistoricalChart from 'src/javascripts/components/charts/AdminHistoricalChart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';

export default class DashboardsIndex {

  constructor() {}

  render() {

    // Initiate admin historical charts
    $('.admin-historical-chart').each(function() {

      // Return if already has c3-data (already initialized)
      // Needed to prevent re-init when navigating away from page, before new body has loaded
      if (typeof $(this).data('c3-chart') === 'undefined') { 

        let chartId = $(this).attr('id');
        let chart = new AdminHistoricalChart($('#' + chartId));

        // Get data, render chart, set up chart, and create connected table, if exists
        return $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      }

    });



  }

}