import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';

export default class CompaniesUnmappedMetricScoresIndex {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Select all metric scores
    $('.select-all-metric-scores').click(function() {
      $('#unmapped_metric_scores_table tr .checkbox').prop('checked', this.checked);
    });

    // Init filter typeaheads
    filterTypeahead('.metric-filter', 'metrics', '/metrics/search?', 83, 'generic');
    
    // On typeahead select, create a filter
    $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value to symbol if a fund; otherwise value
      let val;
      if (typeof suggestion.name !== 'undefined') {
        val = suggestion.value;
      } else if (typeof suggestion.value !== 'undefined') {
        val = suggestion.value;
      } else {
        val = suggestion;
      }

      // Update attr in table
      let attribute = $(this).attr('data-attribute');
      $('#unmapped_metric_scores_table').attr('data-' + attribute, val);

      // Reload
      ReloadWithFilters('unmapped_metric_scores_table');

    });

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })


    // Delete on select
    if ( $(".delete-selected-metric-scores").length > 0 )  {
      $('.delete-selected-metric-scores').click(function() {
        let unmappedMetricScoreIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let unmappedMetricScoreId = $(this).closest('tr').attr('data-record-id');
          unmappedMetricScoreIds.push(unmappedMetricScoreId);
        })
        let params = {};
        params['unmapped_metric_score_ids'] = unmappedMetricScoreIds;
        $.ajax({
          type: 'DELETE',
          url: '/companies/unmapped_metric_scores/bulk_delete?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#unmapped_metric_scores_table').DataTable().ajax.reload();
            ToastCustom('Removed', 'Unmapped metric scores removed', 5000);
            $('.select-all-metric-scores').prop('checked', false);
          },
        });
      })
    }


  }

}