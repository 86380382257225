// Application core js, deferred until after loading of initial page content

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Copy all static images under ../images to the output folder and reference
// with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Core javascripts used on every page of app
require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()

// Bootstrap -- must be in this pack (application.js) to be always found 
// by modals, tooltips, and other Bootstrap JS
import 'bootstrap/dist/js/bootstrap.min';

// Draggable and sortable -- for repositioning images and sections
require("jquery-ui/ui/widgets/draggable")
require("jquery-ui/ui/widgets/sortable")

// Flatpicker datetimepicker
const flatpickr = require("flatpickr");

// Form validations for account link https://github.com/DavyJonesLocker/client_side_validations
// Also require fix to make work with Twitter Typeahead
require('@client-side-validations/client-side-validations')
require('src/javascripts/vendor/client-side-validations-fix.js')

// Jquery upload
require('jquery-ui/ui/widget.js')
require('blueimp-file-upload/js/jquery.fileupload.js')

// Jquery touch
require('jquery-ui-touch-punch/jquery.ui.touch-punch.min.js')

// Summernote (used with client emails)
require('summernote/dist/summernote-bs4.js');

// Intro.js
import 'intro.js/intro.js';

// Slick carousel (used on advisor landing page)
require('slick-carousel/slick/slick.js');

// Font awesome
// https://fontawesome.com/how-to-use/on-the-web/setup/hosting-font-awesome-yourself
// These are large js files, so trying to limit ones we use
import 'src/javascripts/vendor/fontawesome/duotone.js';
import 'src/javascripts/vendor/fontawesome/solid.js';
import 'src/javascripts/vendor/fontawesome/light.js';
import 'src/javascripts/vendor/fontawesome/brands.js';
import 'src/javascripts/vendor/fontawesome/fontawesome.js';
// Stop flickering of icons with Turbolinks
// https://fontawesome.com/how-to-use/on-the-web/using-with/turbolinks
FontAwesome.config.mutateApproach = 'sync'

// Add jquery number plugin
// Used to format numbers for display on inputs
require("src/javascripts/vendor/jquery.number.min.js");

// Datatables: https://datatables.net/download/npm
// Don't need () at end (as in documentation); throws error if include that
require( 'jszip' );
require( 'pdfmake' );
require( 'datatables.net-colreorder-bs4' );
require( 'datatables.net-fixedcolumns/js/dataTables.fixedColumns.min.js' );
// require( 'datatables.net-fixedcolumns-bs4' );
require( 'datatables.net-bs4' );
require( 'datatables.net-buttons-bs4' );
require( 'datatables.net-buttons/js/buttons.flash.js' )();
require( 'datatables.net-buttons/js/buttons.html5.js' );
require( 'datatables.net-responsive-bs4' );

// Need to import jsZip for excel button to show up
// https://stackoverflow.com/questions/37966718/datatables-export-to-excel-button-is-not-showing/45345734
import jsZip from 'jszip';
window.JSZip = jsZip;

// Toasts so can call with js.erb files
import 'bootstrap/js/dist/toast.js';

// Page-specific
import 'src/javascripts/pages/Init';
