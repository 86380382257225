// Set actions for table clicks
// Visit url or load remote dataa on row click
export default function(tableId) {

  // Set table
  let table = $('#' + tableId)

  // Set actions for clicking on a row
  const tableRowClickAction = function(row) {

    // Do not take action if row has 'no-click' class
    if ( !row.hasClass('no-click') && !row.find('td').hasClass('dataTables_empty') ) {

      // Get request url from table data attribute
      let url = row.closest('.table').attr('data-request-url');

      // Replace url formula id with data from row
      if (url.includes(":formula_id")) {
        let formulaId = row.attr('data-formula-id');
        url = url.replace(':formula_id', formulaId);
      } 

      // Replace url slug (featured cause) with data from row
      if (url.includes(":slug")) {
        let slug = row.attr('data-slug');
        url = url.replace(':slug', slug);
      } 

      // Replace url record id with data from row
      if (url.includes(":profile_id")) {
        let profileId = row.attr('data-profile-id');
        let profileType = row.attr('data-profile-type');
        url = url.replace(':profile_id', profileId);
        if (typeof profileType !== 'undefined') {url = url.replace(':profile_type', profileType);}
      }

      // Replace url record id with data from row
      if (url.includes(":record_id")) {
        let recordId = row.attr('data-record-id');
        url = url.replace(':record_id', recordId);
      }

      // Replace url record type with data from row
      if (url.includes(":record_type")) {
        let recordType = row.attr('data-record-type');
        url = url.replace(':record_type', recordType);
      }

      // Replace url token with data from row
      if (url.includes(":token")) {
        let token = row.attr('data-record-token');
        url = url.replace(':token', token);
      }

      // Only continue if url does not include any 'template' strings
      let templateStrings = [":profile_type", ":profile_id", ":formula_id", ":record_id"];
      let i;
      for (i=0;i<templateStrings.length;i++) {
        if (url.indexOf(templateStrings[i]) !== -1) {
          return;
        }
      }

      if (table.hasClass('remote-links')) {
        return $.get(url)
      } else if (table.hasClass('target-blank')) {
        return window.open(url)
      } else {
        return Turbolinks.visit(url)
      }

    }
  };

  // Set click areas
  // Need to avoid triggering action when clicking a checkbox -- used for chart tables to 
  // interact with charts
  let firstTd;
  firstTd = 'tbody > tr > td:first-child';
  $(table).on('click', firstTd, function() { return tableRowClickAction($(this).closest('tr')); });


  const otherTds = 'tbody > tr > td:not(:first-child)';
  return $(table).on('click', otherTds, function() { return tableRowClickAction($(this).closest('tr')); });
};

