import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import FilePicker from 'src/javascripts/components/forms/FilePicker';

export default class CompaniesEdit {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Upload files
    FilePicker();

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })


  }

}