// Nav for landing pages
// Add color and border to landing top nav on scroll
export default function() {

  // Fade in landing icons
  // They start with d-none; fade in once js is loaded
  $('.landing-fadeIn').removeClass('opaque');

  $(window).scroll(function() {

    if ($(window).scrollTop() > 30) {

      return $('.navbar').addClass('nav-landing-bordered');

    } else {

      return $('.navbar').removeClass('nav-landing-bordered');
    }

  });

}