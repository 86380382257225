import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

export default class CommentsEdit {

  constructor() {}

  render() {

    // Companies typeahead
    filterTypeahead('#companies_search', 'companies', '/companies/search?', 172, 'logo');

    // On typeahead select, update hidden field
    $('#companies_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value
      $('#hidden_company_id').val(suggestion.value);

      // Blur
      $(this).blur();

    });

  }

}