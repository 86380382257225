// Add hover button(s) to rows
// Add attributes based on passed data, if data available

export default function(row, linkObjects) {

  // Create hover link container
  let hoverList = '<div class=\'dt-hover-outer\'>'
  hoverList += '<div class=\'dt-hover-inner\'>';

  // Add hover buttons for each link passed as an arg
  $.each(linkObjects, function(index, linkObj) {

    // Set remote option as false by default
    if (typeof linkObj.remote === 'undefined') {linkObj.remote = 'false'}

    // Set class list
    let classList = 'btn btn-primary btn-rounded'
    if ((typeof linkObj.class !== 'undefined') && (linkObj.class.length != 0)) {classList += ' ' + linkObj.class;}

    // Set method
    let method = 'get'
    if ((typeof linkObj.method !== 'undefined') && (linkObj.method.length != 0)) {method = linkObj.method;}

    if (linkObj.link === '#') {

      // Create buttons
      hoverList += '<a class=\'text-white ' + classList + '\''; 

      // Set any additional data
      if ((typeof linkObj.dataList !== 'undefined') && (linkObj.dataList.length != 0)) {
        let i = 0;
        while (i < linkObj.dataList.length) {
          let d = linkObj.dataList[i];
          hoverList += ' ' + d.key + '=\'' + d.value + '\'';
          i++;
        }
      }

      hoverList += '>' + linkObj.name;
      hoverList += '</a>';


    } else if (linkObj.link === 'modal') {

      // Set target
      let target = linkObj.target;

      // Create button
      hoverList += '<a class=\'text-white ' + classList + '\' data-toggle=\'modal\' data-target=\'' + target + '\'>' + linkObj.name + '</a>';

    } else {

      // Create buttons
      hoverList += '<a href=\'' + linkObj.link + '\'';
      hoverList += 'class=\'' + classList + '\'';
      hoverList += 'data-method=\'' + method + '\'';
      hoverList += ' data-remote=' + linkObj.remote;
      if (linkObj.target === '_blank') {hoverList += ' target="_blank"';}
      hoverList += '>' + linkObj.name + '</a>';

    }


  });

  // Close out list
  hoverList += '</div></div>';

  // Append list to row
  return $(row).append(hoverList);

};