import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';

export default class AdminTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner($(this.el).closest('.card'));

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    const dataUrl = BuildApiUrl(tableId);

    // Set additional data vars (used below in table creation)
    const cols = GetCols(this.el);

    // Set order col and dir
    let orderCol = $('#' + tableId).attr('data-order-col');
    if (typeof orderCol === 'undefined') {orderCol = 0};
    let orderDir = $('#' + tableId).attr('data-order-dir');
    if (typeof orderDir === 'undefined') {orderDir = 'asc'};

    // Render table
    // Most table options handled through data attrs on table element
    // https://datatables.net/manual/options
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    // orderCellsTop: required with adding searchable column in header row; without this the clickable ordering
    // gets lost behind search input
    const table = $('#' + tableId).DataTable({
      orderCellsTop: true,
      retrieve: true,
      serverSide: true,
      scrollX: true,
      paging: true,
      pageLength: 10,
      lengthChange: true,
      info: true,
      ordering: true,
      order: [[orderCol, orderDir]],
      searching: true,
      ajax: dataUrl,
      dom: 'tlp',
      columns: cols,
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add hoverlink buttons to each row
        const recordType = data.record_type;
        const recordId = data.record_id;

        // Add hoverlink buttons to rows
        let linkObjects = [];
        let link1 = '/enterprise/' + data.enterprise_url + '/' + data.record_type + '/' + data.record_id;
        let name1 = 'View';
        linkObjects.push({link: link1, name: name1});

        if (!$('#' + tableId).hasClass('no-edit')) {
          let link2 = '/enterprise/' + data.enterprise_url + '/' + data.record_type + '/' + data.record_id + '/edit';
          let name2 = 'Edit';
          linkObjects.push({link: link2, name: name2});
        }

        AddRowHoverButtons(row, linkObjects);

      },
      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();        

      }

    });

    return 'rendered';

  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }


};
