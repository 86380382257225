import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Number display
  NumberDisplay();

  // Auto focus on typeahead when open a dropdown
  $('.dropdown').on('shown.bs.dropdown', function () {
    let input = $(this).find('input');
    if (typeof input !== 'undefined') {
      input.focus();
    }
  })

  // Typeaheads
  filterTypeahead('.companies-filter', 'companies', '/companies/search?', 120, 'logo');
  filterTypeahead('.funds-filter', 'funds', '/funds/search?', 980, 'logo');
  filterTypeahead('.child-funds-filter', 'child_funds', '/funds/search?', 96, 'logo');
  filterTypeahead('.currencies-filter', 'currencies', '/currencies/search?', 44, 'generic');
  filterTypeahead('.countries-filter', 'countries', '/countries/search?', 48, 'generic');

  // On blur
  $('.filter-fund-weights-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    ev.preventDefault();
    $('.tooltip').tooltip('hide');
    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
    hiddenField.val(suggestion.value);
    $(this).typeahead('val', suggestion.name);
    $(this).blur();
  });

  $('.filter-fund-weights-typeahead').bind('typeahead:close', function() {
    $('.tooltip').tooltip('hide');
    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
    let val = $(this).typeahead('val');
    console.log(val);
    if (val === '') {
      hiddenField.val('');
    }
  });


}