import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, emptyTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class CompaniesMappingsIndex {

  constructor() {}

  render() {

	  // EODH Codes //
	  // Start with eodh exchange
	  filterTypeahead('.eodh-exchange-typeahead', 'eodh_exchanges_typeahead', '/eodh/search_exchanges?', 223, 'exchange');

	  // Set eodh code using exchange
	  let setUpEodhCodeTypeahead = function(el) {
	    let et = el.closest('.share-class-set').find('input[type=\'hidden\']').val();
	    let codesUrl = '/eodh/search_codes?exchange=' + et + '&query=';
	    let codesBloodhound = new Bloodhound({
	      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
	      queryTokenizer: Bloodhound.tokenizers.whitespace,
	      remote: {
	        cache: false,
	        url: codesUrl + '%QUERY',
	        wildcard: "%QUERY"
	      }
	    });
	    codesBloodhound.initialize();
	    $(ec).typeahead({
	      hint: true,
	      highlight: true,
	      minLength: 0,
	    }, {
	      name: 'codes',
	      display: 'name_display',
	      limit: 352,
	      source: codesBloodhound,
	      templates: {
	        suggestion(el) {
	          if (el.name === null) {
	            return noResultsTemplate();
	          } else {
	            return genericDisplayTemplate(el.name_display);
	          }
	        }
	      },
	    });
	  }
	  let ec = $('.eodh-code-typeahead');
	  setUpEodhCodeTypeahead(ec);

	  // On select exchange
	  $('.eodh-exchange-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
	    ev.preventDefault();
	    $('.tooltip').tooltip('hide');
	    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
	    hiddenField.val(suggestion.code);
	    $(this).typeahead('val', suggestion.code);
	    $(this).blur();
	    let sc = $(this).closest('.share-class-set');
	    let ec = sc.find('.eodh-code-typeahead');
	    sc.find('.no-exchange').addClass('d-none');
	    ec.typeahead('destroy');
	    ec.val('');
	    ec.removeClass('d-none');
	    setUpEodhCodeTypeahead(ec);
	  });

	  // On select exchange
	  $('.eodh-code-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
	    ev.preventDefault();
	    $('.tooltip').tooltip('hide');
	    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
	    hiddenField.val(suggestion.code);
	    $(this).typeahead('val', suggestion.code);
	    $(this).blur();
	  });


	  // Add share class
	  $('.add-share-class').click(function() {
	    $('#add_share_class').val('true');
	    $('.submit-company-form').click();
	  })

	  // BBG IDs
	  $('.bbg-global-company-id').blur(function() {
	    let val = $(this).val();
	    if (val.length === 0) {
	      $(this).closest('.form-group').find('.hidden-bbg-destroy').val("true");
	    } else {
	      $(this).closest('.form-group').find('.hidden-bbg-destroy').val("false");      
	    }
	  })


  }

}