import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';

export default class CompaniesReviewsIndex {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let reviewersTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(reviewersTypeahead.initializeBloodhound() )
       .then( bloodhound => reviewersTypeahead.render(bloodhound) )
       .then( reviewersTypeahead.setUp('.users-typeahead'));
    }

    // Select all reviews
    $('.select-all-reviews').click(function() {
      $('#reviews_table tr .checkbox').prop('checked', this.checked);
    });

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })


  }

}