import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';

export default class FundsIndex {

  constructor() {}

  render() {

    // Select all funds
    $('.select-all-funds').click(function() {
      $('#funds_table tr .checkbox').prop('checked', this.checked);
    });

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let analystsTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(analystsTypeahead.initializeBloodhound() )
       .then( bloodhound => analystsTypeahead.render(bloodhound) )
       .then( analystsTypeahead.setUp('.users-typeahead'));
    }

  	// Init filter typeaheads
    filterTypeahead('.fund-type-filter', 'fund_type', '/funds/attrs/search?attr=fund_type&', 40, 'generic');
    filterTypeahead('.fund-family-filter', 'fund_family', '/funds/attrs/search?attr=fund_family&', 40, 'generic');
    filterTypeahead('.cap-filter', 'cap', '/funds/attrs/search?attr=cap&', 40, 'generic');
    filterTypeahead('.approach-filter', 'approach', '/funds/attrs/search?attr=approach&', 40, 'generic');
    filterTypeahead('.category-filter', 'category', '/funds/attrs/search?attr=category&', 40, 'generic');
    filterTypeahead('.geography-filter', 'geography', '/funds/attrs/search?attr=geography&', 40, 'generic');
    filterTypeahead('.country-filter', 'country', '/funds/attrs/search?attr=country&', 40, 'generic');
    filterTypeahead('.asset-class-filter', 'asset_class', '/funds/attrs/search?attr=asset_class&', 40, 'generic');

	  // On typeahead select, create a filter
	  $('.filter-funds-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

	    // Prevent default
	    ev.preventDefault();

	    // Return if has class disabled
	    if ($(this).hasClass('disabled')) {return;}

	    // Hide tooltip
	    $('.tooltip').tooltip('hide');

	    // Set value to symbol if a fund; otherwise value
	    let val;
	    if (typeof suggestion.name !== 'undefined') {
	      val = suggestion.value;
	    } else if (typeof suggestion.value !== 'undefined') {
	      val = suggestion.value;
	    } else {
	      val = suggestion;
	    }

	    // Update attr in table
	    let attribute = $(this).attr('data-attribute');
	    $('.admin-table-fixed').attr('data-' + attribute, val);

	    // Reload
	    ReloadWithFilters('funds_table');

	  });

  	// Auto focus on typeahead when open a dropdown
		$('.dropdown').on('shown.bs.dropdown', function () {
			let input = $(this).find('input');
			if (typeof input !== 'undefined') {
				input.focus();
			}
  	})

    // Assign on select
    if ( $(".update-selected-funds").length > 0 )  {
      $('.update-selected-funds').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let fundIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let recordId = $(this).closest('tr').attr('data-record-id');
          fundIds.push(recordId);
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['fund_ids'] = fundIds;
        $.ajax({
          type: 'PATCH',
          url: '/funds/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#funds_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Fund records updated', 5000);
            $('.select-all-funds').prop('checked', false);
          },
        });
      })
    }

  }

}